import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"

import {Box, Grid, Container} from "@material-ui/core"
import XsButton from "../xsButton/xsButton"
import XsConfirmationDialog from "../xsDialog/xsConfirmationDialog"

// import RequestFormStore from "../../../modules/RequestForm/RequestFormStore"
import MicroelectronicFormStore from "../../../modules/microelectronic/microelectronicFormStore"
import GlobalStore from "../../store/GlobalStore"
import RouterStore from "../../store/RouterStore"

import {logout} from "../../helpers/actions"

function XsHeader() {
	return (
		<div className="main_header">
			<Container style={{maxWidth: "90%"}}>
				<Grid container alignItems="center" className="pt-2 pb-2">
					<Grid item container style={{width: 500}}>
						<Grid item>
							<Box
								style={{paddingTop: "28px", paddingRight: "8px"}}
								onClick={() => {
									RouterStore.push("/calls")
								}}
								className="pointer"
							>
								<img src="/public/images/logo_siea_blue.png" />
							</Box>
						</Grid>
						<Grid item>
							<Box
								mr={6}
								onClick={() => {
									RouterStore.push("/calls")
								}}
								className="pointer"
							>
								<img src="/public/images/headerLogoNew.svg" style={{width: "70px", height: "100px"}} />
							</Box>
						</Grid>
					</Grid>
					<Grid item container xs justify="space-between" alignItems="center">
						<Grid item container xs>
							<Grid item style={RouterStore.location.pathname == "/calls" ? {textDecoration: "underline"} : {}}>
								<XsButton
									icon={<i className="fas fa-file-signature fa-2x"></i>}
									variant="text"
									text="Výzvy" //{<FormattedMessage id="Common.label.requests" />}
									onClick={() => {
										if (RouterStore.location.pathname == "/microelectronic") {
											//"/request"
											delayedCallback(300, () => {
												if (MicroelectronicFormStore.isSameFormValues()) {
													MicroelectronicFormStore.formRef.reset()
													RouterStore.push("/calls")
												} else {
													GlobalStore.openConfirmationDialog("xsCloseRequestFormOverRequests")
												}
											})
										} else {
											RouterStore.push("/calls")
										}
									}}
								/>
							</Grid>
							<Grid item style={RouterStore.location.pathname == "/requests" ? {textDecoration: "underline"} : {}}>
								<XsButton
									icon={<i className="fas fa-file-signature fa-2x"></i>}
									variant="text"
									text="Žiadosti"
									onClick={() => {
										RouterStore.push("/requests")
									}}
								/>
							</Grid>
						</Grid>
						<Grid item>
							<XsButton
								icon={<i className="fas fa-sign-out-alt fa-2x"></i>}
								variant="text"
								text="Odhlásiť sa"
								onClick={() => {
									if (RouterStore.location.pathname == "/microelectronic") {
										//request
										delayedCallback(300, () => {
											if (MicroelectronicFormStore.isSameFormValues()) {
												logout()
											} else {
												GlobalStore.openConfirmationDialog("xsCloseRequestFormOverLogout")
											}
										})
									} else {
										logout()
									}
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<XsConfirmationDialog
				name="xsCloseRequestFormOverIcon"
				text="Are you sure you want to leave and discard recent changes?"
				confirmBtn={{
					icon: <i className="fal fa-check" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					MicroelectronicFormStore.formRef.reset()
					RouterStore.push("/welcome")
				}}
			/>
			<XsConfirmationDialog
				name="xsCloseRequestFormOverRequests"
				text="Are you sure you want to leave and discard recent changes?"
				confirmBtn={{
					icon: <i className="fal fa-check" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					MicroelectronicFormStore.formRef.reset()
					RouterStore.push("/requests")
				}}
			/>
			<XsConfirmationDialog
				name="xsCloseRequestFormOverLogout"
				text="Are you sure you want to leave and discard recent changes?"
				confirmBtn={{
					icon: <i className="fal fa-check" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					logout()
				}}
			/>
		</div>
	)
}

export default injectIntl(observer(XsHeader))


import React from "react"
import {observer} from "mobx-react"
import classnames from "classnames"

import Button from "@material-ui/core/Button"

import "./xsButton.less"

// export default observer(({id, className, text, onClick, icon = "", disabled, size = "medium"}) => {

@observer
export default class XsButton extends React.Component {
	render() {
		const {
			id,
			className,
			text,
			onClick,
			icon = "",
			size = "medium",
			variant = "contained",
			color = "primary",
			textColor,
			disabled,
			tabindex,
			disableTextTransform = false
		} = this.props
		return (
			<Button
				id={id}
				className={className}
				variant={variant}
				onClick={onClick}
				disabled={disabled}
				color={color}
				size={size}
				tabIndex={tabindex}
				style={{textTransform: disableTextTransform ? "none" : "uppercase"}}
			>
				{isNotEmpty(icon) && <span style={{marginRight: "8px"}}>{icon}</span>}
				<span className={classnames({[textColor]: isNotEmpty(textColor)})}>{text}</span>
			</Button>
		)
	}
}


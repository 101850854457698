import {observable, action} from "mobx"
import api from "../../global/actions/api"
import {getUserCompanyInfo} from "../../global/helpers/actions"
import GlobalStore from "../../global/store/GlobalStore"
import RegistersCacheStore from "../../global/store/RegistersCacheStore"
import InfoStore from "../../global/store/InfoStore"

class RequestFormStore {
	@observable applicantChipForm = []
	@observable callId = null
	@observable callCode = null
	@observable dropedFiles0 = []
	@observable dropedFiles1 = []
	@observable dropedFiles2 = []
	@observable dropedFiles3 = []
	@observable dropedFilesOther = []
	@observable attachmentTemplates = ["price", "contractScan", "bankAccountScan"]

	formRef = null

	defaultFormValues = {}

	@action resetVals() {
		this.applicantChipForm = []
		this.callId = null
		this.dropedFiles0 = []
		this.dropedFiles1 = []
		this.dropedFiles2 = []
		this.dropedFiles3 = []
		this.dropedFilesOther = []
		this.attachmentTemplates = ["price"]
		this.formRef = null
		this.defaultFormValues = {}
	}

	@action isSameFormValues() {
		return JSON.stringify(this.defaultFormValues) === JSON.stringify(this.formRef.values())
	}

	@action deleteLoadedAttachemnt(id, dropzoneIndex, attachmentIndex) {
		let data = {
			_id: id,
			active: false
		}
		api
			.saveAttachment(data)
			.call()
			.then(() => {
				this[`dropedFiles${dropzoneIndex}`].splice(attachmentIndex, 1)
			})
	}

	@action resetDropzones() {
		this.dropedFiles0 = []
		this.dropedFiles1 = []
		this.dropedFiles2 = []
		this.dropedFiles3 = []
		this.dropedFilesOther = []
	}

	generateDataAndSave(form, version, callback) {
		let droppedFiles = [
			...this.dropedFiles0,
			...this.dropedFiles1,
			...this.dropedFiles2,
			...this.dropedFiles3,
			...this.dropedFilesOther
		]
		if (isSafe(droppedFiles) && droppedFiles.length) {
			let req = []
			let files = droppedFiles
			var saveFunction = this.save
			// const rel_object_id = response._id
			let binaryDataArr = []
			let getdata = function(index) {
				let data = files[index]
				let binaryData
				let typeName = ""
				if (index === 0) typeName = "INN:IPPC124:PRICERESEARCH"
				if (index === 1) typeName = "INN:IPPC124:ORCONTRACT"
				if (index === 2) typeName = "INN:IPPC124:BUACCONTRACT"

				var reader = new FileReader()
				reader.readAsBinaryString(data)
				reader.onloadend = function() {
					binaryData = reader.result
					binaryDataArr.push(binaryData)
					req.push({
						attachment_id: isNotEmpty(data._id) ? data._id : null,
						_ref: false,
						active: true,
						content_type: isNotEmpty(data.type) ? data.type : "application/octet-stream",
						// content: binaryData.split(",")[1], ???
						content: btoa(binaryData),
						type: typeName,
						file_name: data.name,
						size: data.size
					})
					if (files.length > index + 1) {
						getdata(index + 1)
					} else {
						saveFunction(form, req, version, callback)
					}
				}
			}
			getdata(0)
		} else {
			this.save(form, [], version, callback)
		}
	}

	@action save(form, dropzoneData, version, callback) {
		GlobalStore.loading(true)
		const formVals = form.values()

		let req = {
			record_id: isNotEmpty(formVals.request_id) ? formVals.request_id : null, // vyplnaj pri UPDATE
			_type: "INN.API.JSON.Proxy.Request.IPPC124",
			version: isNotEmpty(version) ? version : "draft", // alebo "final"
			status: isNotEmpty(version) && version == "final" ? "sent" : "inp",
			type_id: "INN.Data.Request.IPPC124",
			attached_files: dropzoneData,
			is_diffrent_kores: formVals.companyAddressSameNo == true,
			company_data: {
				company: formVals.companyId,
				identifier: formVals.ico,
				name: formVals.companyName,
				tax_identification_number: formVals.dic,
				vat_identification_number: formVals.icdph,
				company_type: formVals.companyDesc, //(Entity.CL.CompanyType)
				company_size_category: formVals.companySize,
				legal_form: formVals.companyLegalForm, //(Entity.CL.LegalForm)
				iban: formVals.companyIBAN,
				oa_address: {
					// adresa sidla
					country: {
						_type: "Entity.CL.Country",
						_id: "SK"
					},
					obj_city: {
						_type: "Entity.CL.Address.City",
						_id: formVals.companyCity
					},
					primary: true,
					region: {
						_type: "Entity.CL.Address.Region",
						_id: formVals.companyRegion
					},
					street_plain: formVals.companyStreet,
					house_number: formVals.companyStreetNumber,
					type: {
						_type: "Entity.CL.AddressType",
						_id: "OA"
					},
					zip_plain: formVals.companyPSC
				}
			},
			statutories_data: [],
			call: {
				_ref: true,
				_type: "INN.Data.Call",
				_id: formVals.callId
			},
			project_name: formVals.projectName,
			activity_type: formVals.eligibleArea,
			legitimate_activities: formVals.legitimateActivities,
			project_desc_solution_target: formVals.projectDescAndTarget,
			default_situation: formVals.projectBasicParameters,
			project_activity_realization: formVals.projectImplementationMethod,
			project_implementation_deadline: formVals.projectDeadlineCompletion,
			additional_information: formVals.projectAdditionalInfo,
			project_location_realization: formVals.projectRealizationPlace,
			one_company_subjects_identification: formVals.oneCompanySubjectsIdentification,
			better_price_company_data: {
				identifier: formVals.potentialSolverIco,
				name: formVals.potentialSolverName,
				tax_identification_number: formVals.potentialSolverDic,
				vat_identification_number: formVals.potentialSolverIcdph
			},
			total_project_price: formVals.potentialSolverCompletePrice,
			innovation_voucher_amount: formVals.potentialSolverVoucherPrice,
			sub_fund_amount: formVals.potentialSolverCofinancedPrice
		}

		if (formVals.otherSourcesSupportCheckboxYes) req.other_sources_support = true
		if (formVals.otherSourcesSupportCheckboxNo) req.other_sources_support = false

		//Kontaktna osoba
		if (
			isNotEmpty(formVals.contactPersonName) ||
			isNotEmpty(formVals.contactPersonMail) ||
			isNotEmpty(formVals.contactPersonPhone)
		) {
			req["request_contact_data"] = {
				name: formVals.contactPersonName,
				email: formVals.contactPersonMail,
				phone: formVals.contactPersonPhone
			}
		}

		//Pri update sa vlozi ID persony
		if (isNotEmpty(formVals.contactPersonId)) {
			req.request_contact_data["person"] = formVals.contactPersonId
		}

		//Statutari
		let statutarData = isEmpty(formVals.applicantChipForm) ? [] : JSON.parse(formVals.applicantChipForm)
		statutarData.map((statutar) => {
			req["statutories_data"].push({
				statutory_name: statutar.name,
				can_sign: statutar.isStatutar
			})
		})

		//Korespondecna adresa
		if (formVals.companyAddressSameNo == true) {
			req.company_data["ca_address"] = {
				country: {
					_type: "Entity.CL.Country",
					_id: "SK"
				},
				house_number: formVals.companyCorrespondenceStreetNumber,
				obj_city: {
					_type: "Entity.CL.Address.City",
					_id: formVals.companyCorrespondenceCity
				},
				city_plain: "",
				primary: true,
				region: {
					_type: "Entity.CL.Address.Region",
					_id: formVals.companyCorrespondenceRegion
				},
				street_plain: formVals.companyCorrespondenceStreet,
				type: {
					_type: "Entity.CL.AddressType",
					_id: "CA"
				},
				zip_plain: formVals.companyCorrespondencePSC
			}
		}

		req["consent_marketing_information"] = formVals.sentMarketingDataYes

		logger("req", req)
		// if (1 < 2) {
		// 	return null
		// }

		api
			.saveRequest(req)
			.call()
			.then((response) => {
				GlobalStore.setNotificationMessage(
					version == "final" ? "Odoslanie prebehlo úspešne" : "Uloženie prebehlo úspešne",
					"",
					"success"
				)
				if (isSafe(callback)) {
					callback(response._id)
				}
			})
	}

	@action load(form, id, callback) {
		GlobalStore.loading(true)
		api
			.loadRequestWithAtt(id)
			.call()
			.then((response) => {
				form.reset()
				const company = response.company_data || {}
				const potentialSolver = response.better_price_company_data || {}
				const request_contact_data = response.request_contact_data || {}
				const companyAddresses = company.oa_address || {}
				// const isIP_ZS_122 = response.call && response.call.call_type && response.call.call_type.code == "IP_ZS_122"
				// const isIP_ZS2_123 = response.call && response.call.call_type && response.call.call_type.code == "IP_ZS2_123"
				// const isIP_SK2_122 = response.call && response.call.call_type && response.call.call_type.code == "IP_SK2_122"
				// const isIP_SK3_122 = response.call && response.call.call_type && response.call.call_type.code == "IP_SK3_122"
				// const isZSP_123 = response.call && response.call.call_type && response.call.call_type.code == "ZSP_123"

				let formObj = {
					request_id: response.record_id,
					version: response.version,
					status: response.status._id,
					companyId: company.company,
					ico: company.identifier,
					dic: company.tax_identification_number,
					icdph: company.vat_identification_number,
					companyName: company.name,
					companyDesc: isSafe(company.company_type) ? company.company_type._id : "",
					callCode: response.call.call_type.code,
					companySize: isSafe(company.company_size_category) ? company.company_size_category._id : "",
					//companyAddresses
					companyStreet: companyAddresses.street_plain,
					companyStreetNumber: companyAddresses.house_number,
					companyCity: companyAddresses.obj_city,
					companyRegion: companyAddresses.region,
					companyPSC: companyAddresses.zip_plain,
					companyLegalForm: company.legal_form._id,
					companyIBAN: company.iban,
					contactPersonName: request_contact_data.name,
					contactPersonMail: request_contact_data.email,
					contactPersonPhone: request_contact_data.phone,
					contactPersonId: request_contact_data.person,
					callId: response.call._id,
					zoIPNumber: response.innovation_request_id,
					projectName: response.project_name,
					legitimateActivities: response.legitimate_activities,
					projectDescAndTarget: response.project_desc_solution_target,
					projectBasicParameters: response.default_situation,
					projectImplementationMethod: response.project_activity_realization,
					projectAdditionalInfo: response.additional_information,
					projectRealizationPlace: response.project_location_realization,
					projectDeadlineCompletion: response.project_implementation_deadline,
					oneCompanySubjectsIdentification: response.one_company_subjects_identification,
					otherSourcesSupportCheckboxYes: response.other_sources_support === true,
					otherSourcesSupportCheckboxNo: response.other_sources_support === false,
					potentialSolverIco: potentialSolver.identifier,
					potentialSolverName: potentialSolver.name,
					potentialSolverDic: potentialSolver.tax_identification_number,
					potentialSolverIcdph: potentialSolver.vat_identification_number,
					potentialSolverCompletePrice: response.total_project_price,
					potentialSolverVoucherPrice: response.innovation_voucher_amount,
					potentialSolverCofinancedPrice: response.sub_fund_amount,
					sent_date: response.sent_date_time
				}

				// if (isIP_SK2_122 || isIP_SK3_122 || isIP_ZS2_123) {
				// 	formObj["projectRealizationPlace"] = response.project_location_realization
				// 	formObj["projectDeadlineCompletion"] = response.project_implementation_deadline
				// }

				formObj["eligibleArea"] = isSafe(response.activity_type) ? response.activity_type._id : ""
				formObj["eligibleAreaCode"] = isSafe(response.activity_type) ? response.activity_type.code : ""
				formObj["sentMarketingDataYes"] = !!response.consent_marketing_information
				formObj["sentMarketingDataNo"] = !response.consent_marketing_information

				const caAddress = company.ca_address
				if (
					isSafe(caAddress) &&
					(isNotEmpty(caAddress.street_plain) ||
						isNotEmpty(caAddress.house_number) ||
						isNotEmpty(caAddress.obj_city) ||
						isNotEmpty(caAddress.region) ||
						isNotEmpty(caAddress.zip_plain))
				) {
					formObj.companyCorrespondenceStreet = caAddress.street_plain
					formObj.companyCorrespondenceStreetNumber = caAddress.house_number
					formObj.companyCorrespondenceCity = caAddress.obj_city
					formObj.companyCorrespondenceRegion = caAddress.region
					formObj.companyCorrespondencePSC = caAddress.zip_plain

					formObj.companyAddressSameYes = false
					formObj.companyAddressSameNo = true
					form.$("companyCorrespondenceRegion").set("rules", "required")
					form.$("companyCorrespondenceStreet").set("rules", "required")
					form.$("companyCorrespondenceCity").set("rules", "required")
					form.$("companyCorrespondenceStreetNumber").set("rules", "required")
					form.$("companyCorrespondencePSC").set("rules", "required|between:5,5")
				}

				if (isSafe(response.statutories_data) && response.statutories_data.length > 0) {
					let statutors = []
					response.statutories_data.forEach((statutar) => {
						statutors.push({
							name: statutar.statutory_name,
							isStatutar: statutar.can_sign
						})
					})
					formObj.applicantChipForm = JSON.stringify(statutors)
				} else {
					formObj.applicantChipForm = JSON.stringify([{name: "", isStatutar: false}])
				}

				if (isSafe(response.attached_files) && response.attached_files.length) {
					response.attached_files.forEach((attachment) => {
						const blob = b64toBlob(attachment.content)
						let tmp = new Blob([blob], {
							type: attachment.content_type
						})
						tmp.name = attachment.file_name
						tmp._id = attachment.attachment_id
						tmp.databaseType = attachment.type
						if (attachment.type === "INN:IPPC124:PRICERESEARCH") {
							this.dropedFiles0.push(tmp)
						}
						if (attachment.type === "INN:IPPC124:ORCONTRACT") {
							this.dropedFiles1.push(tmp)
						}
						if (attachment.type === "INN:IPPC124:BUACCONTRACT") {
							this.dropedFiles2.push(tmp)
						}
					})
				}

				// if (formObj.callCode == "IP_IPCEI_121") {
				// 	this.handleFormForIpceiRequest(form)
				// }

				// if (formObj.callCode == "IP_SK_121") {
				// 	this.handleFormForSKRequest(form)
				// }

				// if (formObj.callCode == "IP_ZS_122" || formObj.callCode == "IP_ZS2_123") {
				// 	this.handleFormForZSRequest(form)
				// }

				// if (formObj.callCode == "IP_SK2_122" || isIP_SK3_122) {
				// 	this.handleFormForIPSK2(form)
				// }

				// if (formObj.callCode == "ZSP_123") {
				// 	this.handleFormForZSP_123(form)
				// }

				form.set(formObj)

				if (form.$("status").value != "inp" && isNotEmpty(form.$("status").value)) {
					form.$("confirmCheckbox1").set("value", true)
					form.$("confirmCheckbox2").set("value", true)
					form.$("confirmCheckbox3").set("value", true)
					form.$("confirmCheckbox4").set("value", true)
					form.$("confirmCheckbox5").set("value", true)
					form.$("confirmCheckbox6").set("value", true)
					form.$("confirmCheckbox7").set("value", true)

					// form.$("confirmCheckbox12mothsPassed").set("value", true)
					// form.$("confirmCheckboxAddress").set("value", true)
					// form.$("confirmCheckboxAllCorrect").set("value", true)
					// form.$("confirmCheckboxConfirmConsulting").set("value", true)
					// form.$("confirmCheckboxIP2SK5").set("value", true)
				}

				this.defaultFormValues = form.values()
				logger(this.defaultFormValues)
				GlobalStore.loading(false)

				if (isSafe(callback) && typeof callback == "function") {
					callback()
				}
			})
	}

	downloadSpiecificTemplate(uri, name) {
		api.downloadFile(uri, name)
	}

	validateDraft(form, callback) {
		const formVals = form.values()

		//Polia ktore su req pri drafte
		if (
			isEmpty(formVals.companyName) ||
			// isEmpty(isIP_ZS_122 ? formVals.eligibleAreaCode : formVals.projectActivityType) ||
			isEmpty(formVals.companyDesc) ||
			isEmpty(formVals.companyLegalForm) ||
			isEmpty(formVals.companyCity) ||
			isEmpty(formVals.companyRegion) ||
			isEmpty(formVals.companyStreet) ||
			isEmpty(formVals.companyStreetNumber) ||
			isEmpty(formVals.companyPSC) ||
			isEmpty(formVals.dic) ||
			isEmpty(formVals.icdph) ||
			isEmpty(formVals.companyIBAN) ||
			(formVals.companyAddressSameNo == true &&
				(isEmpty(formVals.companyCorrespondenceRegion) ||
					isEmpty(formVals.companyCorrespondenceCity) ||
					isEmpty(formVals.companyCorrespondenceStreet) ||
					isEmpty(formVals.companyCorrespondenceStreetNumber) ||
					isEmpty(formVals.companyCorrespondencePSC)))
		) {
			GlobalStore.setNotificationMessage(
				`Polia "Obchodné meno", Oprávnená oblasť, Základná charakteristika Žiadateľa", "Právna forma", "Obec", "Kraj", "Ulica", "Popisné číslo", "PSČ" sú povinné pre uloženie rozpracovanei verzie`,
				null,
				"error"
			)
		} else {
			callback()
		}
	}

	async loadCodelistAndForm(form, id) {
		GlobalStore.loading(true)
		await RegistersCacheStore.checkRegisters("/request")
		this.load(form, id)
	}

	handleFormForIpceiRequest(form) {
		let ipceiActivity = GlobalStore.CL["activityType"].find((row) => row.code == "accinnconssvc")
		if (isSafe(ipceiActivity)) {
			form.$("projectActivityType").value = ipceiActivity._id
		}
		form.$("projectName").value = "Dokumentácia pre notifikačný proces IPCEI"
		// form.$("projectAdditionalInfo").value =
		// 	"Vypracovanie dokumentácie, alebo jej časti pre potreby notifikačného procesu v rámci tvorby uceleného IPCEI projektu."
		form.$("projectActivityType").set("label", "Oprávnená aktivita IP")
		form.$("projectAdditionalInfo").set("rules", "")
		form.$("projectDescAndTarget").set("rules", "")
		form.$("projectBasicParameters").set("rules", "")
		form.$("projectImplementationMethod").set("rules", "")
	}

	handleFormForSKRequest(form) {
		form.$("projectAdditionalInfo").set("rules", "")
	}

	handleFormForZSRequest(form) {
		form.$("projectAdditionalInfo").set("rules", "")
		form.$("projectActivityType").set("rules", "")
		form.$("eligibleArea").set("rules", "required")

		if (form.$("callCode").value == "IP_ZS2_123") {
			form.$("projectRealizationPlace").set("rules", "required")
			form.$("projectDeadlineCompletion").set("rules", "required")
		}
	}

	handleFormForIPSK2(form) {
		form.$("projectAdditionalInfo").set("rules", "")
		form.$("projectActivityType").set("rules", "")
		form.$("eligibleArea").set("rules", "required")
		form.$("projectRealizationPlace").set("rules", "required")
		form.$("projectDeadlineCompletion").set("rules", "required")
	}

	handleFormForZSP_123(form) {
		form.$("projectAdditionalInfo").set("rules", "")
		form.$("projectActivityType").set("rules", "")
		form.$("eligibleArea").set("rules", "")

		form.$("potentialSolverCofinancedPrice").set("rules", "")
		form.$("potentialSolverCompletePrice").set("rules", "")
		form.$("potentialSolverDic").set("rules", "")
		form.$("potentialSolverIcdph").set("rules", "")
		form.$("potentialSolverIco").set("rules", "")
		form.$("potentialSolverName").set("rules", "")
		form.$("potentialSolverVoucherPrice").set("rules", "")
		form.$("projectBasicParameters").set("rules", "")
		form.$("projectDescAndTarget").set("rules", "")
		form.$("projectImplementationMethod").set("rules", "")
		form.$("projectName").set("rules", "")
		form.$("companyIBAN").set("rules", "")

		form.$("companyAddressSameYes").value = true
		form.$("companyAddressSameNo").value = false
	}

	loadCompanyInfo(form) {
		GlobalStore.loading(true)
		const id = getUserCompanyInfo()._id
		api
			.loadCompanyInfo(id)
			.call()
			.then((company) => {
				form.$("companyName").value = company.name
				form.$("ico").value = company.identifier
				form.$("companyDesc").value = isSafe(company.type) ? company.type.code : ""
				form.$("companyLegalForm").value = isSafe(company.legal_form) ? company.legal_form.code : ""
				form.$("dic").value = company.tax_identification_number
				form.$("icdph").value = company.vat_identification_number
				let orgunit = company.specific_rels.find((row) => row._type == "INN.Data.Entity.OrgUnit")
				if (isSafe(orgunit) && isSafe(orgunit.addresses)) {
					let address = orgunit.addresses.find(
						(row) => row._type == "Entity.Data.Address" && row.active == true && row.type._id == "OA"
					)
					if (isSafe(address)) {
						form.$("companyRegion").value = isSafe(address.region) ? address.region._id : ""
						form.$("companyCity").value = isSafe(address.obj_city) ? address.obj_city._id : ""
						form.$("companyStreet").value = address.street_plain
						form.$("companyStreetNumber").value = address.house_number
						form.$("companyPSC").value = address.zip_plain
					}

					let corespondingAddress = orgunit.addresses.find(
						(row) => row._type == "Entity.Data.Address" && row.active == true && row.type._id == "CA"
					)

					if (isSafe(corespondingAddress) && !form.$("callCode").value == "ZSP_123") {
						form.$("companyAddressSameYes").value = false
						form.$("companyAddressSameNo").value = true
						form.$("companyCorrespondenceRegion").value = isSafe(corespondingAddress.region)
							? corespondingAddress.region._id
							: ""
						form.$("companyCorrespondenceCity").value = isSafe(corespondingAddress.obj_city)
							? corespondingAddress.obj_city._id
							: ""
						form.$("companyCorrespondenceStreet").value = corespondingAddress.street_plain
						form.$("companyCorrespondenceStreetNumber").value = corespondingAddress.house_number
						form.$("companyCorrespondencePSC").value = corespondingAddress.zip_plain
					}

					let account = orgunit.accounts.find((row) => row._type == "Fin.Data.BankAccount")
					if (isSafe(account)) {
						form.$("companyIBAN").value = account.number
					}
				}

				this.checkRegionAvailability(form)
				GlobalStore.loading(false)
			})
			.catch(() => {
				GlobalStore.loading(false)
			})
	}

	checkRegionAvailability(form) {
		const {callId, companyRegion} = form.values()
		if (isNotEmpty(callId) && isNotEmpty(companyRegion)) {
			const req = {
				region_id: companyRegion,
				call_id: callId
			}

			api
				.regionRestriction(req)
				.call()
				.then((response) => {
					if (isSafe(response) && response.is_allowed === false) {
						InfoStore.open(
							"Aktuálne nie je možné podať ŽoIP so sídlom podnikania v rámci Vášho kraja. Viac informácií je dostupných v rámci oznámenia zverejnenom na webovom sídle www.siea.sk/inovacie/inovacnepoukazky/ .",
							"Upozornenie"
						)
					}
				})
		}
	}
}

var singleton = new RequestFormStore()
export default singleton


"use strict"

import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"

import {Container, Grid, Typography} from "@material-ui/core"

import Login from "./login"
import ForgotPassword from "./forgotPassword"

import LoginStore from "./loginStore"

function getScreen(screen) {
	switch (screen) {
		case LoginStore.screens.FORGOT:
			return <ForgotPassword />
		default:
			return <Login />
	}
}

function getHeaderText(screen) {
	switch (screen) {
		case LoginStore.screens.FORGOT:
			return <Typography variant="h3">Resetovať heslo</Typography> //Obnovenie prihlasovacích údajov
		default:
			return <Typography variant="h3">Vitajte na portáli INOWA</Typography>
	}
}

function LoginContainer() {
	return (
		<React.Fragment>
			<div className="main_header">
				<Container style={{maxWidth: "90%"}}>
					<Grid container alignItems="center" justify="space-between" className="pt-2 pb-2">
						<Grid item>
							<div>
								<img
									src="/public/images/logo_siea_blue.png"
									style={{paddingBottom: "22px", paddingRight: "16px"}}
									className="pointer"
									onClick={() => (LoginStore.screen = LoginStore.screens.LOGIN)}
								/>
								<img
									src="/public/images/logo_inovujme_backoffice.png"
									style={{width: "100px", height: "100px"}}
									className="pointer"
									onClick={() => (LoginStore.screen = LoginStore.screens.LOGIN)}
								/>
							</div>
						</Grid>
						<Grid item className="header_gradient">
							{getHeaderText(LoginStore.screen)}
						</Grid>
						<Grid item style={{width: 368}}></Grid>
					</Grid>
				</Container>
			</div>
			<Container maxWidth="lg">
				<Grid container justify="center">
					<Grid
						item
						style={{
							maxWidth: "400px",
							width: "400px"
						}}
					>
						<Grid container direction="column" justify="center" style={{height: "65vh"}}>
							{getScreen(LoginStore.screen)}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
		// <React.Fragment>
		// 	<AppBar position="static" alignitems="center">
		// 		<Toolbar className="main_toolbar">
		// 			<Grid container>
		// 				<Grid item>
		// 					<img src="/public/images/logo.png" />
		// 				</Grid>
		// 			</Grid>
		// 		</Toolbar>
		// 	</AppBar>
		// 	<Grid container justify="center">
		// 		<Grid item style={{maxWidth: "400px", width: "400px"}}>
		// 			<Grid container direction="column" justify="center" style={{height: "80vh"}}>
		// 				{getScreen(LoginStore.screen)}
		// 			</Grid>
		// 		</Grid>
		// 	</Grid>
		// </React.Fragment>
	)
}

export default injectIntl(observer(LoginContainer))


import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import {Container, Box, Grid, Typography, Link} from "@material-ui/core"
import CallDetailStore from "./callDetailStore"
import moment from "moment"
import XsButton from "../../global/ui/xsButton/xsButton"
import RouterStore from "../../global/store/RouterStore"
// import RequestFormStore from "../RequestForm/RequestFormStore"
import renderHTML from "react-render-html"
import CloseCallDialog from "./closeCallDialog"
import CloseCallDialogStore from "./CloseCallDialogStore"
import CloseRegionCallDialogStore from "./closeRegionCallDialogStore"
import CloseRegionCallDialog from "./closeRegionCallDialog"
import XsTable from "../../global/ui/xsTable/xsTable"
import classNames from "classnames"
import {getUserAccessRights} from "../../global/helpers/actions"

function callDetail(props) {
	const userHasFullRigts = getUserAccessRights() == "FULL"

	useEffect(() => {
		CallDetailStore.loadCallInfo(props.id)

		return () => {
			CallDetailStore.restrictionBA = ""
		}
	}, [])

	const callData = CallDetailStore.data || {}

	const columns = [
		{
			Header: "Názov kraja",
			width: 120,
			accessor: "NameExt"
		},
		{
			Header: "Platný od",
			width: 150,
			accessor: "ValidityFrom",
			sortType: (rowA, rowB) => {
				const sent_date_time_A = rowA.original.ValidityFrom
				const sent_date_time_B = rowB.original.ValidityFrom
				if (sent_date_time_A > sent_date_time_B) return -1
				if (sent_date_time_B > sent_date_time_A) return 1
				return 0
			},
			Cell: (props) => <div>{isNotEmpty(props.value) ? moment(props.value).format("DD.MM.YYYY HH:mm") : ""}</div>
		},
		{
			Header: "Platný do",
			width: 150,
			accessor: "ValidityTo",
			sortType: (rowA, rowB) => {
				const sent_date_time_A = rowA.original.ValidityTo
				const sent_date_time_B = rowB.original.ValidityTo
				if (sent_date_time_A > sent_date_time_B) return -1
				if (sent_date_time_B > sent_date_time_A) return 1
				return 0
			},
			Cell: (props) => <div>{isNotEmpty(props.value) ? moment(props.value).format("DD.MM.YYYY HH:mm") : ""}</div>
		},
		{
			Header: "Posledná zmena (Kto)",
			width: 120,
			accessor: "UpdatedBy"
		},
		{
			clickable: false,
			width: 10,
			accessor: "Code",
			Cell: (obj) => {
				const isDeleted = obj.row.original.status == "rem" || !userHasFullRigts
				return (
					<React.Fragment>
						{isNotEmpty(obj.value) && (
							<Grid container direction={"row"} justify="flex-end" spacing={"2"}>
								<Grid item>
									<i
										className={classNames("fas fa-pencil fa-lg", {
											"blueDark pointer": !isDeleted,
											greyLight: isDeleted
										})}
										onClick={() => !isDeleted && CloseRegionCallDialogStore.open(obj.row.original)}
									></i>
								</Grid>
							</Grid>
						)}
					</React.Fragment>
				)
			}
		}
	]

	const columnsForDateTable = [
		{
			Header: "Výzva",
			width: 120,
			accessor: "state"
		},
		{
			Header: "Platná od",
			width: 150,
			accessor: "ValidityFrom",
			sortType: (rowA, rowB) => {
				const sent_date_time_A = rowA.original.ValidityFrom
				const sent_date_time_B = rowB.original.ValidityFrom
				if (sent_date_time_A > sent_date_time_B) return -1
				if (sent_date_time_B > sent_date_time_A) return 1
				return 0
			},
			Cell: (props) => <div>{isNotEmpty(props.value) ? moment(props.value).format("DD.MM.YYYY HH:mm") : ""}</div>
		},
		{
			Header: "Platný do",
			width: 150,
			accessor: "ValidityTo",
			sortType: (rowA, rowB) => {
				const sent_date_time_A = rowA.original.ValidityTo
				const sent_date_time_B = rowB.original.ValidityTo
				if (sent_date_time_A > sent_date_time_B) return -1
				if (sent_date_time_B > sent_date_time_A) return 1
				return 0
			},
			Cell: (props) => <div>{isNotEmpty(props.value) ? moment(props.value).format("DD.MM.YYYY HH:mm") : ""}</div>
		},
		// prazdne stlpce aby sedelo zarovania s tabulkou pod
		{
			Header: " ",
			width: 120,
			accessor: "x"
		},
		{
			Header: " ",
			width: 10,
			accessor: "xx"
		}
	]

	const getCallFromDate = () => {
		return callData.OpenSequence == 1
			? isSafe(callData.From)
				? callData.From
				: ""
			: isSafe(callData.InitialOpenFrom)
			? callData.InitialOpenFrom
			: ""
	}

	const getCallToDate = () => {
		return callData.OpenSequence == 1
			? isSafe(callData.To)
				? callData.To
				: ""
			: isSafe(callData.InitialOpenTo)
			? callData.InitialOpenTo
			: ""
	}

	return (
		<Container maxWidth="lg">
			<Grid container direction="column">
				<Grid item>
					<Box pt={2} pb={2}>
						<Typography variant="h4" className="header_gradient">
							{callData.CallTypeNameExt}
						</Typography>
					</Box>
				</Grid>
				<Grid item>
					<Typography variant="h5">{callData.CallTypeCode}</Typography>
				</Grid>
				<Grid item>
					<br />
				</Grid>
				<React.Fragment>
					<Grid item>
						<Typography variant="body2">
							<b>Dátum vyhlásenia Výzvy:</b> {/* OpenSequence > 1   callData.InitialOpenFro : From*/}
							{callData.OpenSequence == 1
								? isSafe(callData.From)
									? moment(callData.From, "").format("DD.MM.YYYY HH:mm")
									: ""
								: isSafe(callData.InitialOpenFrom)
								? moment(callData.InitialOpenFrom, "").format("DD.MM.YYYY HH:mm")
								: ""}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="body2">
							<b>Dátum uzavretia Výzvy:</b>{" "}
							{/* {isSafe(callData.InitialOpenTo) ? moment(callData.InitialOpenTo, "").format("DD.MM.YYYY HH:mm") : ""} */}
							{moment(getCallToDate(), "").format("DD.MM.YYYY HH:mm")}
						</Typography>
					</Grid>
					<br />
					{callData.OpenSequence != 1 && (
						<React.Fragment>
							<Grid item>
								<Typography variant="body2">
									<b>Dátum {callData.OpenSequence}. vyhlásenia Výzvy:</b>{" "}
									{isSafe(callData.From) ? moment(callData.From, "").format("DD.MM.YYYY HH:mm") : ""}
								</Typography>
							</Grid>
							<Grid item>
								<Typography variant="body2">
									<b>Dátum {callData.OpenSequence}. uzavretia Výzvy:</b>{" "}
									{isSafe(callData.To) ? moment(callData.To, "").format("DD.MM.YYYY HH:mm") : ""}
								</Typography>
							</Grid>
						</React.Fragment>
					)}
				</React.Fragment>

				{callData.CallTypeCode == "IP_SK_121" &&
					isNotEmpty(CallDetailStore.restrictionBA) &&
					CallDetailStore.restrictionBA}
				<Grid item>
					<br />
				</Grid>
				<Grid item>
					<Typography variant="body2">
						<b>Základné informácie o Výzve</b>
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body2">{renderHTML(`<span> ${callData.BasicInformation} </span>`)} </Typography>
				</Grid>
				<Grid item>
					<br />
				</Grid>
				{/* <Grid item>
					<Typography variant="body2">
						<b>Upozornenie</b>
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body2">{callData.Caution}</Typography>
				</Grid> */}
				<Grid item>
					<br />
				</Grid>
				<Grid item>
					<Typography variant="body2">
						<b>Dokumentáciu k Výzve nájdete na odkaze</b>
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body2">
						<Link
							// className="white pointer"
							onClick={() => window.open("https://www.siea.sk/inovacie/inovacnepoukazky/ ", "_blank")}
						>
							www.siea.sk/inovacie/inovacnepoukazky
						</Link>
					</Typography>
				</Grid>
				<Grid item>
					<br />
				</Grid>

				<Grid item>
					{
						<Grid item container xs={12} spacing={2} className="pb-8">
							<XsTable
								columns={columnsForDateTable}
								data={[{state: callData.State, ValidityFrom: callData.From, ValidityTo: callData.To}]}
							/>
						</Grid>
					}
				</Grid>

				<Grid item>
					<br />
				</Grid>

				{CallDetailStore.regionRestrictions.length > 0 && (
					<Grid item container xs={12} spacing={2} className="pb-8">
						<XsTable columns={columns} data={CallDetailStore.regionRestrictions} />
					</Grid>
				)}

				<Grid item container xs={12} spacing={2}>
					{/* <XsButton
						className={"mr-2"}
						onClick={() => {
							delayedCallback(300, () => {
								RequestFormStore.callId = callData.ID
								RequestFormStore.callCode = callData.CallTypeCode
								RouterStore.push(`/request`)
							})
						}}
						color="primary"
						text={"VYPLNIŤ ŽIADOSŤ"}
					/> */}
					<Grid item>
						<XsButton
							onClick={() => {
								delayedCallback(300, () => {
									RouterStore.push(`/calls`)
								})
							}}
							color="primary"
							text={"Zavrieť"}
						/>
					</Grid>
					<Grid item>
						<XsButton
							onClick={() => {
								delayedCallback(300, () => {
									CloseCallDialogStore.open({From: getCallFromDate(), To: getCallToDate()})
								})
							}}
							color="primary"
							variant="outlined"
							text={"Platnosť Výzvy"}
							disabled={callData.State === "Uzavretá" || !userHasFullRigts}
						/>
					</Grid>
				</Grid>
			</Grid>
			{CloseCallDialogStore.isOpen && <CloseCallDialog id={callData.ID} />}
			{CloseRegionCallDialogStore.isOpen && <CloseRegionCallDialog id={callData.ID} />}
		</Container>
	)
}

export default injectIntl(observer(callDetail))


import React, {useEffect, useState} from "react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import dvr from "mobx-react-form/lib/validators/DVR"
import moment from "moment"
import SolverRequestsStore from "./solverRequestsStore"
import {Container, Box, Grid, Typography, Link} from "@material-ui/core"
import XsTable from "../../global/ui/xsTable/xsTable"
import RouterStore from "../../global/store/RouterStore"
import XsButton from "../../global/ui/xsButton/xsButton"
import StornoRequestDialogStore from "./stornoRequestDialogStore"
import StornoRequestDialog from "./stornoRequestDialog"
import XsDateTimePicker from "../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsInput from "../../global/ui/xsInput/xsInput"
import XsIconButton from "../../global/ui/xsButton/xsIconButton"
import XsSearchSelect from "../../global/ui/xsSearchSelect/xsSearchSelect"
import GlobalStore from "../../global/store/GlobalStore"
import classnames from "classnames"

function solverRequestsList() {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			SolverRequestsStore.loadListData(form)
		},
		onError() {}
	}

	let [form] = useState(
		new MobxReactForm(
			{
				fields: {
					date_from: {label: "Dátum od", type: "date"},
					date_to: {label: "Dátum do", type: "date"},
					company_name: {label: "Obchodné meno"},
					identifier: {label: "IČO"},
					contact: {label: "Kontaktná osoba"},
					request_status: {label: "Stav žiadosti"}
				}
			},
			{plugins: {dvr: dvr(validatorjs)}, hooks}
		)
	)

	if (isSafe(SolverRequestsStore.filterFormRef)) {
		form = SolverRequestsStore.filterFormRef
	}
	SolverRequestsStore.filterFormRef = form

	const columns = [
		{
			Header: "Dátum odoslania žiadosti",
			width: 150,
			accessor: "sent_date_time",
			sortType: (rowA, rowB) => {
				const sent_date_time_A = rowA.original.sent_date_time
				const sent_date_time_B = rowB.original.sent_date_time
				if (sent_date_time_A > sent_date_time_B) return -1
				if (sent_date_time_B > sent_date_time_A) return 1
				return 0
			},
			Cell: (props) => <div>{isNotEmpty(props.value) ? moment(props.value).format("DD.MM.YYYY HH:mm") : ""}</div>
		},
		{
			Header: "Obchodné meno",
			width: 150,
			accessor: "company_data.name",
			sortType: (rowA, rowB) => {
				const send_date_time_A = isNotEmpty(rowA.original.To) ? rowA.original.To : ""
				const send_date_time_B = isNotEmpty(rowB.original.To) ? rowB.original.To : ""
				if (send_date_time_A > send_date_time_B) return -1
				if (send_date_time_B > send_date_time_A) return 1
				return 0
			}
		},
		{
			Header: "IČO",
			width: 120,
			accessor: "company_data.identifier"
		},
		{
			Header: "Kontaktná osoba",
			width: 200,
			accessor: "request_contact_data.name",
			sortType: "basic"
		},
		{
			Header: "Stav žiadosti ",
			width: 150,
			accessor: "status_name_ext",
			sortType: "basic"
		},
		{
			Header: "Poznámka",
			width: 150,
			accessor: "removed_reason",
			sortType: "basic"
		},
		//
		{
			clickable: false,
			width: 10,
			accessor: "record_id",
			Cell: (obj) => {
				const isDeleted = obj.row.original.status == "rem" || obj.row.original.status == "sent"
				return (
					<React.Fragment>
						{isNotEmpty(obj.value) && (
							<Grid container direction={"row"} justify="flex-end" spacing={"2"}>
								<Grid item>
									<i
										className={classnames("fas fa-trash-alt fa-lg", {
											"redDanger pointer": !isDeleted,
											greyLight: isDeleted
										})}
										onClick={() => !isDeleted && StornoRequestDialogStore.open(obj.value)}
									></i>
								</Grid>
							</Grid>
						)}
					</React.Fragment>
				)
			}
		}
	]

	useEffect(() => {
		SolverRequestsStore.loadCodeListsAndGetRequests(form)
	}, [])

	return (
		<Container maxWidth="lg">
			<Grid container direction="column">
				<Grid item>
					<Box pt={2} pb={2}>
						<Typography variant="h4" className="header_gradient">
							Zoznam žiadostí
						</Typography>
					</Box>
				</Grid>
				<Grid item>
					<Typography variant="body2">
						Nachádzate sa na stránke, prostredníctvom ktorej sa môžete zapojiť do otvorenej Výzvy na predkladanie
						žiadostí o zaradenie do zoznamu oprávnených riešiteľov inovačných projektov v rámci NP ZIVSE.
					</Typography>
				</Grid>
				<Grid item>
					<br />
				</Grid>
				<Grid item>
					<Typography variant="body2">
						V prípade požiadavky o zmenu údajov uvedených v žiadosti prosím kontaktujte náš tím na e-mailovej adrese:{" "}
						<Link className="pointer" onClick={() => window.open("mailto:inovacnepoukazky@siea.gov.sk")}>
							inovacnepoukazky@siea.gov.sk
						</Link>
					</Typography>
				</Grid>
				<Grid item>
					<br />
				</Grid>
				<Grid item>
					<Typography variant="body2">
						Dokumentáciu k Výzve nájdete na odkaze:{" "}
						<Link
							className="pointer"
							onClick={() => window.open("https://www.siea.sk/inovacie/inovacnepoukazky/", "_blank")}
						>
							www.siea.sk/inovacie/inovacnepoukazky/
						</Link>
					</Typography>
				</Grid>
				<Grid item>
					<br />
				</Grid>
				<Grid item>
					<br />
				</Grid>
				<Grid item>
					<br />
				</Grid>
				<Grid item>
					<Typography variant="body2">Kliknutím na žiadosť si pozriete detail žiadosti</Typography>
				</Grid>
			</Grid>
			<Grid container direction="row-reverse">
				<Grid item>
					<XsButton
						className="xs-info"
						text={"Export žiadostí do PDF"}
						onClick={() => {
							SolverRequestsStore.exportList()
						}}
					/>
				</Grid>
			</Grid>

			<Grid container direction="row" spacing={1} className="mt-5">
				<Grid item xs={2}>
					<XsDateTimePicker field={form.$("date_from")} />
				</Grid>
				<Grid item xs={2}>
					<XsDateTimePicker field={form.$("date_to")} />
				</Grid>
				<Grid item xs={2}>
					<XsInput field={form.$("company_name")} />
				</Grid>
				<Grid item xs={1}>
					<XsInput field={form.$("identifier")} />
				</Grid>
				<Grid item xs={2}>
					<XsInput field={form.$("contact")} />
				</Grid>
				<Grid item xs={2}>
					<XsSearchSelect field={form.$("request_status")} items={GlobalStore.CL["SolverRequestStatus"]} />
				</Grid>
				<Grid item direction="row">
					<XsIconButton
						rect
						className="xs-default xs-outline fa-pull-right"
						tooltip={"Aplikovať filtre"}
						onClick={form.onSubmit}
						icon={
							<i
								className="far fa-search fa-lg xs-greyDefault"
								style={{paddingTop: "8px", paddingBottom: "8px", paddingLeft: "3px", paddingRight: "3px"}}
							></i>
						}
					/>
					<XsIconButton
						rect
						className="xs-default xs-outline fa-pull-right mr-1"
						tooltip={"Resetovať filtre"}
						variant="outlined"
						color="primary"
						onClick={(e) => {
							form.reset()
							form.onSubmit(e)
						}}
						icon={
							<i
								className="far fa-times fa-lg xs-greyDefault"
								style={{paddingTop: "8px", paddingBottom: "8px", paddingLeft: "3px", paddingRight: "3px"}}
							></i>
						}
					/>
				</Grid>
			</Grid>

			<Box pt={2}>
				<Grid container>
					<Grid item xs={12}>
						<Box pt={1}>
							<XsTable
								columns={columns}
								data={SolverRequestsStore.listData}
								onRowClick={(row) => RouterStore.push(`/solverRequest?id=${row.record_id}`)}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<StornoRequestDialog
				formReset={() => {
					SolverRequestsStore.loadListData(form)
				}}
			/>
		</Container>
	)
}

export default injectIntl(observer(solverRequestsList))


// import {FormattedMessage} from "react-intl"
// import React from "react"
// import {/*getPerson, */ getUser} from "../../global/helpers/actions"
// import moment from "moment"

export default {
	load() {
		return {
			fields: {
				dateFrom: {
					label: "Dátum prijatia žiadosti OD",
					type: "date"
				},
				dateTo: {
					label: "Dátum prijatia žiadosti DO",
					type: "date"
				},
				callCode: {
					label: "Kód Výzvy"
				},
				callStatus: {
					label: "Stav žiadosti"
				},
				ico: {
					label: "IČO"
				},
				zoipNumber: {
					label: "Číslo ŽoIP"
				}
			}
		}
	}
}
